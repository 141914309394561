body {
  font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto";
  src: url("/src/fonts/Roboto/Roboto-Regular.ttf")
}

.main-container-custom {
  min-height: calc(100vh - 176px);
}

label {
  user-select: none;
}

.inline-size-max{
  inline-size: max-content;
}

.min-inline-size-fit{
  min-inline-size: fit-content;
}