.progress_desktop_outer_container{
    background-color: #00154D;
    font-family: 'Roboto Slab', serif;
    min-height: calc(100vh - 176px);
}

.progress_desktop_current_step{
    font-weight: 700;
    opacity: 1;
}

.progress_desktop_step_completed{
    opacity: 60%;
}

//necessary to account for opacity conflicts during map function
.progress_desktop_current_step.progress_desktop_step_completed{
    font-weight: 700;
    opacity: 1;
}

.progress_desktop_job_site_current_step{
    font-weight: 600;
    opacity: 1;
}

.progress_desktop_job_site_not_current_step{
    opacity: 30%;
    font-weight: 400;
}

.progress_desktop_job_site_step_completed{
    opacity: 60%;
}

.progress_desktop_job_site_current_step.progress_desktop_job_site_step_completed{
    font-weight: 600;
    opacity: 1;
}