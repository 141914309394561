$navy-blue-opacity-90: #00154D;
$roboto-slab: 'Roboto Slab', serif;

.landing_outer_container_desktop {
  min-height: calc(100vh - 188px);
}

.landing_outer_container_mobile {
  min-height: calc(100vh - 168px);
}

.landing_blue_left_panel_desktop {
  background-color: $navy-blue-opacity-90;
}

.landing_blue_left_panel_mobile {
  background-color: $navy-blue-opacity-90;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing_blue_panel_header_text_desktop {
  margin-left: 25%;
  margin-right: 25%;
  font-family: $roboto-slab;
}

.landing_blue_panel_header_text_mobile {
  font-family: $roboto-slab;
}

.landing_blue_panel_sub_text_desktop {
  margin-left: 25%;
  margin-right: 25%;
  font-family: $roboto-slab;
  font-size: 16px;
}

.landing_blue_panel_sub_text_mobile {
  font-size: 14px;
  font-family: $roboto-slab;
}

.landing_gunite_plaster_columns_desktop {
  align-items: center;
}