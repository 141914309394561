@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.modal-enter {
  animation: slideInFromRight 0.3s forwards;
}

.modal-exit {
  animation: slideOutToRight 0.3s forwards;
}
