@keyframes slideInFromRight {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

@keyframes slideOutToRight {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}

.drawerSlideIn {
  animation: slideInFromRight 0.3s forwards ease-in-out;
}

.drawerSlideOut {
  animation: slideOutToRight 0.3s forwards ease-in-out;
}
