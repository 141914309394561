.allColumnsWrapper::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

.allColumnsWrapper::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #f7f4ed;
}

.allColumnsWrapper::-webkit-scrollbar-thumb {
  background: #8B92A6;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

.allColumnsWrapper::-webkit-scrollbar-thumb:hover {
  background: #00154D;
}

.hideSpacer {
  display: none;
}
