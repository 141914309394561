.column {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.column::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.draggable {
  cursor: move;
}

.draggable#dragging {
  opacity: .5;
}
