.topbar_outer_container{
    background-color: #00154D;
    height: 100px;
    align-items: center;
}


.topbar_main_logo {
    text-align: initial;
}
